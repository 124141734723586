/* eslint-disable react/prop-types */
import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
import Loading from "../Loading";
import store from "@store";
import routes from "./routes";

const LineLayout = (props) => {
  const { isAuthenticated, currentUser, setAppState } = store();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("line:accessToken");
    fetch(`/auth/verify-current-user`, {
      headers: {
        'Authorization': accessToken
      }
    })
      .then((response) => response.json())
      .then(({ current_user }) => {
        if (current_user) {
          setAppState({
            currentUser: current_user,
            isAuthenticated: !!current_user,
          });
        } else {
          localStorage.removeItem("line:accessToken");
          setAppState({ isAuthenticated: false, currentUser: null });
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (currentUser) {
      // const { pathname } = props.location;
      // if (
      //   ["linesettings", "lineclient-registration"].includes(
      //     pathname.replace(/\//g, "")
      //   )
      // )
      //   return;
      // if (currentUser?.client?.id) return;

      // const nextUrl = localStorage.getItem("next");
      // localStorage.removeItem("next");
      // props.history.push({
      //   pathname: nextUrl ? nextUrl : "/line/settings",
      //   state: { from: pathname },
      // });
    }
  }, [currentUser]);

  if (loading) return <Loading />;

  return (
    <Container className="line-layout">
      <Suspense key="router" fallback={<Loading />}>
        <Switch>
          {routes.map((route, index) => {
            if (route?.path.includes('line')) {
              return (
                <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
              )
            } else {
              return (
                <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
              )
            }
          })}
        </Switch>
      </Suspense>
    </Container>
  );
};

export default LineLayout;
